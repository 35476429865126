import "./assests/styles/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const helmetContext = {};
root.render(
  <HelmetProvider context={helmetContext}>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </HelmetProvider>
);

reportWebVitals();
