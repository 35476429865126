import Sidebar from "./components/sidebar/sidebar";
import style from "./article.module.scss";
import { NavLink, useParams } from "react-router-dom";
import { useContext } from "react";
import YouTube from "react-youtube";
import parse from "html-react-parser";
import { ArticlesContext } from "../../../../services/context/articlesContext";
import { counterView } from "../../../../apis/articles";
import Card from "../card/card";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

export default function Article() {
  const { blog } = useContext(ArticlesContext);

  const id = useParams().article;

  const urlRead = id.replace(/-/g, " ");

  let index = 0;
  let previous;
  let next;

  for (let i = 0; i < blog.length; i++) {
    if (blog[i].url === urlRead) {
      index = i;
      previous = i + 1;
      next = i - 1;
      if (next < 0) {
        next = blog.length - 1;
      }
      if (previous === blog.length) {
        previous = 0;
      }
      break;
    }
  }

  const data = blog.slice(index, index + 1);

  const isNext = blog.slice(next, next + 1);
  const urlReadNext = isNext[0].url.replace(/\s/g, "-");
  const isPrevious = blog.slice(previous, previous + 1);
  const urlReadPrevious = isPrevious[0].url.replace(/\s/g, "-");

  const date = new Date(data[0].date.seconds * 1000);
  const options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const publishDate = date.toLocaleDateString("fr-FR", options);

  const handleCount = (id, view) => {
    view++;
    counterView(id, view);
  };

  // Récupère le nom du fichier à télécharger
  function extractFileName(url) {
    const segments = url.split("/");
    const filenameWithExtension = segments[segments.length - 1];
    const filename = decodeURIComponent(filenameWithExtension.split("?")[0]);
    return filename.replace("articles/", "");
  }

  // shuffleArticle footer
  const shuffleArticleArray = blog.filter(
    (obj) => !data.some((item) => item.title === obj.title)
  );

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffleArray(shuffleArticleArray);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{data[0].title} - Frédéric Szymañski</title>
          <meta
            name="description"
            content="Frédéric Szymañski - Blog, articles, écrits et news"
          />
          <meta
            property="og:url"
            content={`https://frederic-szymanski.com/#/blog/${id}`}
          />
          <meta
            name="keywords"
            content="articles, blog, news, écrits, théorie, journal, Frédéric Szymañski, cours de guitare, leçons de guitare, prof de guitare, cours de basse, leçons de basse, prof de basse, classique, électrique, guitare 8 cordes, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta property="og:image" content={data[0].banner} />
          <meta property="og:image:alt" content={`${id} image`} />
          <meta property="twitter:image" content={data[0].banner} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <main>
        <div className={`${style.main} d-flex`}>
          <Sidebar data={data[0].content} />
          <div className={`${style.content} d-flex f-column`}>
            <section
              className={`${style.title} d-flex f-column`}
              id="introduction"
            >
              <h1>{data[0].title}</h1>
              <p>
                Posté le {publishDate} par {data[0].publish}.
              </p>
            </section>
            <section className={`${style.introduction} d-flex f-column`}>
              {parse(data[0].intro)}
              <img src={data[0].banner} alt={`bannière de ${data[0].title}`} />
            </section>
            {data[0].content.map((a, i) => (
              <section key={i} id={`bloc${i + 2}`}>
                {i === Math.floor(data[0].content.length / 2) &&
                  data[0].content.length > 5 && (
                    <img
                      className={`${style.banner}`}
                      src={data[0].banner}
                      alt="Bannière"
                    />
                  )}
                <h2 className={`${style.h2}`}>{a.title}</h2>
                {a.content.map((b, ind) => (
                  <>
                    {b.type === "title" && (
                      <h3
                        className={`${style.h3}`}
                        key={ind}
                        id={`title${i + 2}.${ind}`}
                      >
                        {b.content}
                      </h3>
                    )}
                    {b.type === "paragraphe" && (
                      <div className={style.parse}>{parse(b.content)}</div>
                    )}
                    {b.type === "image" && (
                      <img
                        className={`${style.documentImg}`}
                        src={b.content}
                        alt={b.content}
                        key={ind}
                      />
                    )}
                    {b.type === "sound" && (
                      <div className={`${style.centeredDiv}`}>
                        <audio key={ind} controls controlsList="nodownload">
                          <source src={b.content} />
                        </audio>
                      </div>
                    )}
                    {b.type === "video" && (
                      <YouTube key={ind} videoId={b.content} />
                    )}
                    {b.type === "download" && (
                      <div className={`${style.centeredDiv}`}>
                        <button className={`${style.btn}`}>
                          <a
                            href={b.content}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            Télécharger {extractFileName(b.content)}
                          </a>
                        </button>
                      </div>
                    )}
                  </>
                ))}
                {i === data[0].content.length - 1 &&
                  data[0].content.length < 6 && (
                    <img
                      className={`${style.banner}`}
                      src={data[0].banner}
                      alt={`bannière de ${data[0].title}`}
                    />
                  )}
              </section>
            ))}
          </div>
        </div>
        <section
          className={`${style.navigationFooter} d-flex f-column aic jcc`}
        >
          <h2>Autres articles</h2>
          <nav className={`${style.nav} d-flex aic jcsb`}>
            <NavLink
              to={`/blog/${urlReadPrevious}`}
              onClick={() => handleCount(isPrevious[0].url, isPrevious[0].view)}
            >
              <i className="fa-solid fa-arrow-left"></i>
              {isPrevious[0].title}
            </NavLink>
            <h3 className={style.h3}>
              {index + 1} / {blog.length}
            </h3>
            <NavLink
              className={style.right}
              to={`/blog/${urlReadNext}`}
              onClick={() => handleCount(isNext[0].url, isNext[0].view)}
            >
              {isNext[0].title}
              <i className="fa-solid fa-arrow-right"></i>
            </NavLink>
          </nav>
          <div className={`d-flex f-wrap aic jcc`}>
            {shuffleArticleArray.slice(0, 3).map((a, i) => (
              <Card key={i} data={a} />
            ))}
          </div>
        </section>
      </main>
    </>
  );
}
