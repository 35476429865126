import style from "./blog.module.scss";
import Card from "./components/card/card";
import { useContext } from "react";
import { ArticlesContext } from "../../services/context/articlesContext";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

export default function Blog() {
  const { blog } = useContext(ArticlesContext);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Écrits - Frédéric Szymañski</title>
          <meta
            name="description"
            content="Frédéric Szymañski - Blog, articles, écrits et news"
          />
          <meta
            name="keywords"
            content="articles, blog, news, écrits, théorie, journal, Frédéric Szymañski, cours de guitare, leçons de guitare, prof de guitare, cours de basse, leçons de basse, prof de basse, classique, électrique, guitare 8 cordes, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/blog"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726754875/fred/rtgnzo1qzrljykmlnqpj.png"
          />
          <meta property="og:image:alt" content="écrits image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726754875/fred/rtgnzo1qzrljykmlnqpj.png"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <main className={`${style.blog} d-flex f-column aic`}>
        <section>
          <h1>ARTICLES & NEWS</h1>
        </section>
        <section className={"d-flex jcse f-wrap"}>
          {blog.map(
            (a, i) =>
              a.category !== "FAQ" && a.enable && <Card data={a} key={i} />
          )}
        </section>
      </main>
    </>
  );
}
