import { Helmet } from "react-helmet";
import { rate } from "../../json/rate/rate";
import Card from "./components/card/card";
import style from "./rate.module.scss";
import { HelmetProvider } from "react-helmet-async";

export default function Rate() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Les avis des élèves - Frédéric Szymañski</title>
          <meta
            name="description"
            content="Frédéric Szymañski - Les avis des élèves"
          />
          <meta
            name="keywords"
            content="avis, évaluation, notes, cours de guitare, cours de basse, rate, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/avis"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726756196/fred/tqn4h34nqdd4jvkp4d3k.png"
          />
          <meta property="og:image:alt" content="avis image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726756196/fred/tqn4h34nqdd4jvkp4d3k.png"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <main className={`${style.grid}`}>
        <section className="d-flex jcc">
          <h1>LES ÉLÈVES EN PARLENT</h1>
        </section>
        <section className={`${style.rateList} d-flex aic jcse f-wrap`}>
          {rate.map((a, i) => (
            <Card data={a} key={i} />
          ))}
        </section>
      </main>
    </>
  );
}
