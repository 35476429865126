import { Helmet } from "react-helmet";
import About from "./components/about/about";
import Header from "./components/header/header";
import Interaction from "./components/interactions/interaction";
import Methode from "./components/methode/methode";
import News from "./components/news/news";
import Rate from "./components/rate/rate";
import style from "./homepage.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../services/SEO/seo";
import { useEffect, useState } from "react";
import axios from "axios";
AOS.init();

export default function Homepage() {
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   axios.get("/api/data").then((response) => [setData(response.data)]);
  // }, []);

  // console.log(data);

  return (
    <>
      {/* <HelmetProvider>
        <Helmet>
          <title>
            Cours de guitare & basse de qualité sur Noeux-les-mines secteur Lens
            Liévin Béthune Bruay-la-buissière - Frédéric Szymañski
          </title>
          <meta property="og:url" content="https://frederic-szymanski.com/" />
          <meta
            name="keywords"
            content="cours de guitare, électrique, classique, basse, fretless, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            name="description"
            content="Frédéric Szymañski - Cours de guitare et basse de qualité à Noeux-les-mines secteur Lens, Liévin, Béthune, Bruay-la-Buissière ou par webcam via Discord !"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1725962482/glb0wcbv3nssxuvrjkvz.png"
          />
          <meta property="og:image:alt" content="homepage image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1725962482/glb0wcbv3nssxuvrjkvz.png"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider> */}
      <SEO
        title={
          "Cours de guitare & basse de qualité sur Noeux-les-mines secteur Lens Liévin Béthune Bruay-la-buissière - Frédéric Szymañski"
        }
        description={
          "Frédéric Szymañski - Cours de guitare et basse de qualité à Noeux-les-mines secteur Lens, Liévin, Béthune, Bruay-la-Buissière ou par webcam via Discord !"
        }
        path={""}
        keywords={
          "cours de guitare, électrique, classique, basse, fretless, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
        }
        image={
          "https://res.cloudinary.com/dfydbnurt/image/upload/v1725962482/glb0wcbv3nssxuvrjkvz.png"
        }
      />
      <div className={`${style.container}`}>
        <Header />
        <main id="main" className={`${style.main}`}>
          <About />
          <Methode />
          <Interaction />
          <Rate />
          <News />
        </main>
      </div>
    </>
  );
}
