import { Suspense, useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import ShortHeader from "./components/short Header/shortHeader";
import Footer from "./components/footer/footer";
import Provider from "./services/provider/provider";
import ArticlesProvider from "./services/provider/articlesProvider";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const [loc, setLoc] = useState();
  const location = useLocation();
  const helmetContext = {};

  useEffect(() => {
    setLoc(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {/* <HelmetProvider context={helmetContext}> */}
      <Suspense>
        {loc !== "/" && <ShortHeader />}
        <ArticlesProvider>
          <Outlet />
        </ArticlesProvider>
        <Footer />
        <ScrollRestoration />
      </Suspense>
      {/* </HelmetProvider> */}
    </>
  );
}

export default App;
