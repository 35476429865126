import BassCourse from "./coursBasse.js";
import FAQ from "../FaQ/FAQ.js";
import { bass } from "../../../json/bass";
import Legend from "../legend/legend";
import style from "../cours.module.scss";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

export default function Bass() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            Cours de basse électrique avec la méthode Continuum - Frédéric
            Szymañski
          </title>
          <meta
            name="description"
            content="Frédéric Szymañski - Les cours de basse électrique avec la méthode Continuum"
          />
          <meta
            name="keywords"
            content="cours de basse, leçons de basse, prof de basse, basse fretless, basse 5 cordes, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/cours/basse"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755848/fred/ygfmex22i2yfflwpp7q8.png"
          />
          <meta property="og:image:alt" content="cours basse image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755848/fred/ygfmex22i2yfflwpp7q8.png"
          />
        </Helmet>
      </HelmetProvider>
      <main className={`${style.cours} d-flex f-column aic`}>
        <BassCourse />
        {/* <Legend /> */}
        <table className={`${style.table}`} cellspacing="0" cellPadding="0">
          <thead>
            <tr>
              <th className={`${style.bgTableSecondary2}`} scope="col">
                NIVEAUX
              </th>
              <th
                className={`${style.bgTableSecondary5}  ${style.th}`}
                scope="col"
              >
                ÉTAPES IMPORTANTES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                className={`${style.bgTablePrimary1} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Débutant</div>
              </th>
              <td className={`${style.bgTableSecondary1}`}>
                <ul>
                  {bass.level1.map((a, i) => (
                    <li key={i}>
                      <span className={a.color}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary2} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Moyen</div>
              </th>
              <td className={`${style.bgTableSecondary2}`}>
                <ul>
                  {bass.level2.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary3} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Avancé</div>
              </th>
              <td className={`${style.bgTableSecondary3}`}>
                <ul>
                  {bass.level3.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary4} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate2}`}>
                  École du Pro - Mode Conservatoire
                </div>
              </th>
              <td className={`${style.bgTableSecondary4}`}>
                <ul>
                  {bass.level4.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary5} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate2}`}>École du Virtuose</div>
              </th>
              <td className={`${style.bgTableSecondary5}`}>
                <ul>
                  {bass.level5.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <FAQ />
      </main>
    </>
  );
}
