import style from "./header.module.scss";
import logo from "../../../../assests/images/logo n°3 (Noir sur transparent) Fred.webp";
import Burger from "./components/burger";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Header() {
  const [width, setWidth] = useState(window.innerWidth);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const marginTop = 0;

      const scrollToY =
        element.getBoundingClientRect().top + window.scrollY - marginTop;

      window.scrollTo({ top: scrollToY, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (width < 769) {
      setTimeout(() => {
        scrollToSection("header");
      }, 1500);
    }
  }, [width]);

  return (
    <header className={`${style.header}`} id="header">
      <section className={`${style.container} d-flex ais jcc`}>
        <div className={`${style.left} d-flex aic jce`}>
          <div className={`${style.leftContainer} d-flex aic jcc`}>
            <div className={`${style.logo}`}>
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
        <div id="header" className={`${style.right} d-flex aic jcs`}>
          <div className={`${style.rightContainer} d-flex f-column aic jcc`}>
            <Burger />
            <p className={`${style.p}`}>
              Bienvenue sur le site de Frédéric Szymañski,
              <br />
              professeur de guitare et basse depuis 1995 et auteur des méthodes
              Continuum guitare et Continuum basse.
              <br />
              Je propose des cours et du "daily grind" pour d'autres profs &
              musiciens professionnels mais j'ai aussi beaucoup de plaisr à
              enseigner à des débutants désireux d'apprendre correctement ou à
              des musiciens de niveau moyen ou avancé n'ayant pas peur de se
              remettre en question.
            </p>
            <div className={`${style.cours} d-flex f-column aic`}>
              <h1>COURS D'INSTRUMENTS</h1>
              <div className={`${style.listBtn} d-flex aic jcsb`}>
                <button className={`${style.btn} btn btn-primary`}>
                  <NavLink title="GUITARE" to={"/cours/guitare"}>
                    GUITARE
                  </NavLink>
                </button>
                <button className={`${style.btn} btn btn-secondary`}>
                  <NavLink title="BASSE" to={"/cours/basse"}>
                    BASSE
                  </NavLink>
                </button>
                <button className={`${style.btn} btn btn-accent`}>
                  <NavLink title="CONTACT" to={"/contact"}>
                    CONTACT
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${style.scroll}`}
          onClick={() => scrollToSection("main")}
        >
          <div className={`${style.point}`}></div>
        </div>
      </section>
    </header>
  );
}
