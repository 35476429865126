import style from "./cours.module.scss";
import methodeguitare from "../../assests/images/methodeguitare.jpg";
import methodebasse from "../../assests/images/methodebasse.jpg";
import Constants from "../../oldies/stats";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

function RandomSentence() {
  const [text, setText] = useState("");
  useEffect(() => {
    let r_text = new Array();
    r_text[0] = `CHOOSE YOUR WEAPON!`;
    r_text[1] = `IT'S DANGEROUS TO GO ALONE! TAKE THIS.`;

    var i = Math.floor(r_text.length * Math.random());
    setText(r_text[i]);
  }, []);

  return <b>{text}</b>;
}

export default function Cours() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            Méthodes et cours de guitare & basse - Frédéric Szymañski
          </title>
          <meta
            name="description"
            content="Frédéric Szymañski - Les cours de guitare ou de basse avec les méthodes Continuum"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/cours"
          />
          <meta
            name="keywords"
            content="prof de guitare, prof de basse, cours de guitare, cours de basse, leçons de guitare, leçons de basse, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755361/fred/mdrvhcx6li7puyq9uxjx.png"
          />
          <meta property="og:image:alt" content="cours image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755361/fred/mdrvhcx6li7puyq9uxjx.png"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <main className={`${style.cours} d-flex f-column aic`}>
        <section id="Présentation" className={`${style.intro}`}>
          <h1>LES COURS ET MÉTHODES</h1>
          <p>
            Accédez à la méthode <b>Continuum</b> et aux cours qui vont avec et{" "}
            <b>progressez toujours plus !</b> 😉
          </p>
        </section>
        <section id="Phrase" className={`${style.intro}`}>
          {RandomSentence()}
        </section>
        <section id="Méthodes" className={`${style.methodes} d-flex aic jcsb`}>
          <article className={`${style.article} d-flex aic`}>
            <NavLink to={"/cours/guitare"}>
              <div className={`${style.content} d-flex f-column aic`}>
                <h2>GUITARE</h2>
                <div className={`${style.img}`}>
                  <img
                    src={methodeguitare}
                    alt="Continuum guitare - par Frédéric Szymañski"
                  />
                </div>
                <p>
                  Les cours avec Continuum guitare, une méthode de{" "}
                  {Constants.pagesguitare} pages qui a fait ses preuves depuis
                  2009,
                  <br />
                  elle convient aussi bien à des débutants sans prérequis
                  voulant apprendre correctement qu'à des musiciens
                  professionnels pouvant l'utiliser comme "daily grind".
                </p>
              </div>
            </NavLink>
          </article>
          <article className={`${style.article} d-flex aic`}>
            <NavLink to={"/cours/basse"}>
              <div className={`${style.content} d-flex f-column aic`}>
                <h2>BASSE</h2>
                <div className={`${style.img}`}>
                  <img
                    src={methodebasse}
                    alt="Continuum basse - par Frédéric Szymañski"
                  />
                </div>
                <p>
                  Les cours avec Continuum basse, une méthode de{" "}
                  {Constants.pagesbasse} pages qui a fait ses preuves depuis
                  2009,
                  <br />
                  elle convient aussi bien à des débutants sans prérequis
                  voulant apprendre correctement qu'à des musiciens
                  professionnels pouvant l'utiliser comme "daily grind".
                </p>
              </div>
            </NavLink>
          </article>
        </section>
      </main>
    </>
  );
}
