import GuitarCourse from "./coursGuitare.js";
import FAQ from "../FaQ/FAQ.js";
import { guitar } from "../../../json/guitar";
import Legend from "../legend/legend";
import style from "../cours.module.scss";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

export default function Guitar() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            Cours de guitare électrique avec la méthode Continuum - Frédéric
            Szymañski
          </title>
          <meta
            name="description"
            content="Frédéric Szymañski - Les cours de guitare électrique avec la méthode Continuum"
          />
          <meta
            name="keywords"
            content="cours de guitare, leçons de guitare, prof de guitare, classique, electrique, guitare 8 cordes, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/cours/guitare"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755508/fred/ndttwnfy3zaekhvz9lqt.png"
          />
          <meta property="og:image:alt" content="cours guitare image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1726755508/fred/ndttwnfy3zaekhvz9lqt.png"
          />
        </Helmet>
      </HelmetProvider>
      <main className={`${style.cours} d-flex f-column aic`}>
        <GuitarCourse />
        {/* <Legend /> */}
        <table className={`${style.table}`} cellspacing="0" cellPadding="0">
          <thead>
            <tr>
              <th className={`${style.bgTableSecondary2}`} scope="col">
                NIVEAUX
              </th>
              <th
                className={`${style.bgTableSecondary5}  ${style.th}`}
                scope="col"
              >
                ÉTAPES IMPORTANTES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                className={`${style.bgTablePrimary1} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Débutant</div>
              </th>
              <td className={`${style.bgTableSecondary1}`}>
                <ul>
                  {guitar.level1.map((a, i) => (
                    <li key={i}>
                      <span className={a.color}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary2} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Moyen</div>
              </th>
              <td className={`${style.bgTableSecondary2}`}>
                <ul>
                  {guitar.level2.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary3} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate}`}>Avancé</div>
              </th>
              <td className={`${style.bgTableSecondary3}`}>
                <ul>
                  {guitar.level3.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary4} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate2}`}>
                  École du Pro - Mode Conservatoire
                </div>
              </th>
              <td className={`${style.bgTableSecondary4}`}>
                <ul>
                  {guitar.level4.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th
                className={`${style.bgTablePrimary5} ${style.th}`}
                scope="col"
              >
                <div className={`${style.rotate2}`}>École du Virtuose</div>
              </th>
              <td className={`${style.bgTableSecondary5}`}>
                <ul>
                  {guitar.level5.map((a, i) => (
                    <li key={i}>
                      <span className={`${a.color}`}>
                        <span className={"display"}>{a.type}</span>
                      </span>
                      {a.page}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <FAQ />
      </main>
    </>
  );
}
