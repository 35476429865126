import { Helmet } from "react-helmet-async";

export default function SEO({ title, description, path, keywords, image }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://frederic-szymanski.com/#/${path}`} />
      <meta
        property="og:url"
        content={`https://frederic-szymanski.com/#/${path}`}
      />
      <meta name="keywords" content={keywords} />
      <meta property="og:image" content={image} />
      <meta property="twitter:image" content={image} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}
