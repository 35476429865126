import { Helmet } from "react-helmet";
import style from "./contact.module.scss";
import Form from "./form/formMobile";
import { HelmetProvider } from "react-helmet-async";

export default function Contact() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Prendre contact - Frédéric Szymañski</title>
          <meta
            name="description"
            content="Frédéric Szymañski - Prendre contact pour un cours"
          />
          <meta
            name="keywords"
            content="contacter, Frédéric Szymañski, cours de guitare, leçons de guitare, prof de guitare, cours de basse, leçons de basse, prof de basse, classique, électrique, guitare 8 cordes, tapping, sweeping, legato, slap, jazz, metal, funk, Béthune, Liévin, Lille, Noeux les mines, Lens, Bruay la buissière, Sains en gohelle, Mazingarbe, Hersin Coupigny, Barlin, Verquin, webcam, cam, nord, pas de calais, hauts de France, 62, 59"
          />
          <meta
            property="og:url"
            content="https://frederic-szymanski.com/#/contact"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1725962482/glb0wcbv3nssxuvrjkvz.png"
          />
          <meta property="og:image:alt" content="contact image" />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/dfydbnurt/image/upload/v1725962482/glb0wcbv3nssxuvrjkvz.png"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <main>
        <section className={`${style.contact} d-flex f-column aic jcc`}>
          <h1>PRENDRE CONTACT</h1>
          <div className={`${style.form} form d-flex f-column aic jcc`}>
            <section className={`${style.title} d-flex f-column aic`}>
              <div className={`${style.banner}`}>
                <h2>
                  Frédéric Szymañski - Cours de guitare et basse de qualité !
                </h2>
                <p>
                  Cours de guitare électrique et basse électrique sur
                  Noeux-les-mines ou par webcam
                </p>
              </div>
            </section>
            <Form />
          </div>
        </section>
      </main>
    </>
  );
}
